import React, { useState } from 'react'
import PropTypes from 'prop-types'


export const AddCategory = ({setCategories}) => {

    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        if (inputValue.trim().length > 2) {
            setCategories(cats => [inputValue, ...cats]);
            setInputValue('');            
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className='ui icon input'>
            <input 
                type='text'
                value={ inputValue }
                onChange={handleInputChange}
            ></input>
            <i className="search icon"></i>
            </div>
        </form>
    );
}

AddCategory.propTypes = {
    setCategories: PropTypes.func.isRequired
}